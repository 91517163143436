/**
 * mode 主题名称
 * name 主题名称
 * layout 布局方式
 * brandTheme 主题色 工具栏滑入与菜单图标颜色
 * headerBcImg 顶部菜单背景图片
 * headerActive 顶部菜单文字选中颜色
 * headerText 顶部菜单文字默认颜色
 * layoutBcImg body背景图片
 * sildBarBc 左侧菜单背景颜色
 * sildBarActive 左侧菜单选中背景颜色
 * sildBarHover 左侧菜单滑入颜色
 * toolbarRightShow 是否显示工具栏
 * typicalExample 更换主题功能图片
 * */ 
const THEME_CONFIG = [
    {
        mode: "default",
        name: '默认',
        layout: "mix",
        brandTheme: "#3C7FFF",
        headerBcImg:
            "url(https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/theme/default/header.png)",
        headerActive: "#FEA71D",
        headerText: '#FFFFFF',
        layoutBcImg:
            "url(https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/theme/default/body.png)",
        sildBarBc: "#EDF0F7",
        sildBarActive: "#3C7FFF",
        sildBarHover: "rgba(0,0,0,0.08)",
        toolbarRightShow: true,
        typicalExample: 'url(https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/theme/default/typicalExample.png)'
    },
    {
        mode: "colorfulSpringLights",
        name: '炫彩春光',
        layout: "mix",
        brandTheme: "#0F8D99",
        headerBcImg:
            "url(https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/theme/colorfulSpringLights/header.png)",
        headerActive: "#FCFC32",
        headerText: '#FFFFFF',
        layoutBcImg:
            "url(https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/theme/colorfulSpringLights/body.png)",
        sildBarBc: "#E6F4F5",
        sildBarActive: "#0F8D99",
        sildBarHover: "rgba(0,0,0,0.08)",
        toolbarRightShow: true,
        typicalExample: 'url(https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/theme/colorfulSpringLights/typicalExample.png)'
    },
    {
        mode: 'greenSeaSchool',
        name: '绿海学堂',
        layout: "mix",
        brandTheme: "#0F8D99",
        headerBcImg:
            "url(https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/theme/greenSeaSchool/header.png)",
        headerActive: "#FCFC32",
        headerText: '#FFFFFF',
        layoutBcImg:
            "url(https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/theme/greenSeaSchool/body.png)",
        sildBarBc: "#E6F4F5",
        sildBarActive: "#0F8D99",
        sildBarHover: "rgba(0,0,0,0.08)",
        toolbarRightShow: true,
        typicalExample: 'url(https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/theme/greenSeaSchool/typicalExample.png)'
    },
    {
        mode: 'spiritualMystery',
        name: '灵动秘境',
        layout: "mix",
        brandTheme: "#5A4BFA",
        headerBcImg:
            "url(https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/theme/spiritualMystery/header.png)",
        headerActive: "#FCFC32",
        headerText: '#FFFFFF',
        layoutBcImg:
            "url(https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/theme/spiritualMystery/body.png)",
        sildBarBc: "#E7E6F5",
        sildBarActive: "#5A4BFA",
        sildBarHover: "rgba(0,0,0,0.08)",
        toolbarRightShow: false,
        typicalExample: 'url(https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/theme/spiritualMystery/typicalExample.png)'
    },
    {
        mode: 'greenness',
        name: '绿意盎然',
        layout: "mix",
        brandTheme: "#4C733F",
        headerBcImg:
            "url(https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/theme/greenness/header.png)",
        headerActive: "#FCFC32",
        headerText: '#FFFFFF',
        layoutBcImg:
            "url(https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/theme/greenness/body.png)",
        sildBarBc: "#E2EBDF",
        sildBarActive: "#4C733F",
        sildBarHover: "rgba(0,0,0,0.08)",
        toolbarRightShow: true,
        typicalExample: 'url(https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/theme/greenness/typicalExample.png)'
    },
    {
        mode: 'dazzlingSailing',
        name: '炫动起航',
        layout: "mix",
        brandTheme: "#3C7FFF",
        headerBcImg:
            "url(https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/theme/dazzlingSailing/header.png)",
        headerActive: "#3C7FFF",
        headerText: '#333333',
        layoutBcImg:
            "url(https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/theme/dazzlingSailing/body.png)",
        sildBarBc: "#F5F7FA",
        sildBarActive: "#3C7FFF",
        sildBarHover: "rgba(0,0,0,0.08)",
        toolbarRightShow: true,
        typicalExample: 'url(https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/theme/dazzlingSailing/typicalExample.png)'
    },
    {
        mode: 'nationalDay',
        name: '盛世华诞',
        layout: "mix",
        brandTheme: "#EF563D",
        headerBcImg:
            "url(https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/theme/nationalDay/header.png)",
        headerActive: "#FCFC32",
        headerText: '#F1F0FC',
        layoutBcImg:
            "url(https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/theme/nationalDay/body.png)",
        sildBarBc: "#FFE4E4",
        sildBarActive: "#EF563D",
        sildBarHover: "rgba(0,0,0,0.08)",
        toolbarRightShow: false,
        typicalExample: 'url(https://minio.xinkaoyun.com:9000/cloud-campus/miniapp/theme/nationalDay/typicalExample.png)'
    }
];

export default THEME_CONFIG;
